import SEO from '../../components/seo'
import React from 'react'
import Layout from '../../components/layout'
import TotalPrimaryCareLogo from '../../images/TM-Primary-Care-logo.png'
import HeaderImg from '../../components/HeaderImg/HeaderImg'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import { JavascriptIcon } from '../../components/icons/JavascriptIcon'
import { TypescriptIcon } from '../../components/icons/TypescriptIcon'
import { ReactIcon } from '../../components/icons/ReactIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'
import { TwilloIcon } from '../../components/icons/TwilloIcon'
import Slider from '../../components/Slider/Slider'
import Testimonial from '../../components/Testimonial/Testimonial'
import TextBlock from '../../components/TextBlock/TextBlock'
import Goals from '../../components/Goals/Goals'
import Outcomes from '../../components/Outcomes/Outcomes'
import BottomNav from '../../components/BottomNav/BottomNav'

const TotalPrimaryCarePage = () => {
  const services = [
    {
      id: 1,
      text: 'Custom Application Development',
    },
    {
      id: 2,
      text: 'Project management',
    },
    {
      id: 3,
      text: '5 people team',
    },
    {
      id: 5,
      text: '3rd party API Integration',
    },
    {
      id: 6,
      text: 'CRM Development',
    },
  ]

  const techs = [
    {
      id: 1111,
      icon: JavascriptIcon(),
    },
    {
      id: 6666,
      icon: TypescriptIcon(),
    },
    {
      id: 2222,
      icon: ReactIcon(),
    },
    {
      id: 4444,
      icon: HtmlIcon(),
    },
    {
      id: 5555,
      icon: TwilloIcon(),
    },
  ]

  const slides = [
    {
      id: 1,
      img:
        'https://cdn.emersoft.co/emersoft-website/total-primary-care/total-primary-care-slide-1.png',
      alt: 'Total Primary Care team meeting with Emersoft',
    },
    {
      id: 2,
      img:
        'https://cdn.emersoft.co/emersoft-website/total-primary-care/total-primary-care-slide-2.png',
      alt: 'Pawel from Emersoft in the Total Primary Care office',
    },
  ]

  const goals = [
    {
      id: 11,
      text:
        'To design and develop a custom-built CRM platform.',
    },
    {
      id: 22,
      text:
        'To create an intuitive interface for franchise clients',
    },
    {
      id: 33,
      text: 'To reduce expenses on unnecessary features and improve overall system performance',
    },
    {
      id: 44,
      text: 'To provide a reliable CRM platform with superior customer support for quick troubleshooting and resolutions',
    },
  ]

  const outcomes = [
    {
      id: 111,
      title: '60%',
      subtitle: 'Reduction in training hours',
    },
    {
      id: 222,
      title: '80%',
      subtitle: 'Faster patient data retrieval',
    },
    {
      id: 333,
      title: '5',
      subtitle: 'months to deliver the MVP',
    },
  ]

  return (
    <Layout>
      <SEO
        title="Total Primary Care"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `digital agency`,
          `Total Primary Care`,
          `Angular`,
          `Healthcare`,
        ]}
      />
      <HeaderImg
        header="header-img--total-primary-care"
        img={TotalPrimaryCareLogo}
      />
      <ProjectOverview
        title="Total Primary Care"
        category='Healthcare'
        link="https://totalmens.com/"
        services={services}
        technologies={techs}
        subtitle="Creating a custom-designed Patient CRM"
        paragraph1="Total Care Primary Care is a medical company that helps people start their own healthcare clinics. They offer a full package for new clinics, including medical knowledge, clinic design, and even their own brand. Their mission is to make healthcare more patient-friendly by using a team of trained doctors and smart tech."
      />
      <Testimonial
        paragraph="Working with Emersoft is a smooth ride - proactive and straight-talking. Just what we needed"
        author="Robert Sek"
        authorRole="CEO and Founder of Total Primary Care"
      />
      <TextBlock
        heading="Brief"
        paragraph1="The client's rapidly growing business was hindered by their existing CRM, which offered limited customer support and extra costly features."
        paragraph2="To take control of the situation, they decided to build a tailored CRM software aligned to their unique needs."
      />
      <Slider slides={slides} />
      <Goals goalsList={goals} />
      <TextBlock
        heading="Our Process"
        paragraph1="Starting with a thorough understanding of the current CRM usage, we planned out what a basic, but fully functional, product would look like. Our development process used two-week 'sprints' where we made regular updates and improvements"
        paragraph2="We held weekly meetings to answer questions and add any new features the client requested. This process was flexible and responsive, ensuring a well-tailored, end-product."
      />
      <Outcomes outcomes={outcomes} />
      <BottomNav
        heading="See More"
        firstLink="/our-work/miracle-dental-associates"
        firstHeading="Miracle Dental Associates"
        secondLink="/our-work/lightspeedvt"
        secondHeading="LightSpeedVT"
      />
    </Layout>
  )
}

export default TotalPrimaryCarePage;